<script lang="ts" setup>
import { computed } from "vue";
import { mdiClipboardTextMultipleOutline } from "@mdi/js";

const breadcrumbs = computed(() => [{ default: "IAM-ProjectsOverview" }]);
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <inn-breadcrumbs :items="breadcrumbs"></inn-breadcrumbs>
    <v-container fluid>
      <header class="app-welcome-message">
        <h2>
          <v-icon>{{ mdiClipboardTextMultipleOutline }}</v-icon> Projecten
        </h2>
      </header>
      <v-row>
        <v-col cols="2" class="sidebar">
          <v-list nav>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-ProjectsOverview' }">
                <v-list-item-title>Overzicht</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col>
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
